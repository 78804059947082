<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="page-title">
          <h1>Contrate um plano</h1>
          <p class="lead text-muted">
            <!-- Preparamos planos especiais para você e seu time.<br /> -->
            Além de ter acesso a mais recursos, você apoia o FIGHTBACK.
          </p>
        </div>

        <h4>Escolha seu plano</h4>
        <b-list-group class="plans-selector">
          <b-list-group-item>
            <b-form-radio
              id="planSolo"
              v-model="checkoutSubscription.plan"
              name="selectedPlan"
              value="solo"
            ></b-form-radio>
            <label for="planSolo" class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-0">
                  <i class="fas fa-user-shield"></i> Plano Premium
                </h5>
                <p class="lead mb-0">R$ 19,90/<small>mês</small></p>
              </div>
              <ul class="list-unstyled mb-0">
                <li>Comentários em partidas privadas</li>
                <li>100 Partidas privadas por mês</li>
                <li>Busca Inteligente</li>
                <li>Collections/Favoritos</li>
                <li>Compartilhe partidas com outros usuários</li>
              </ul>
            </label>
          </b-list-group-item>

          <b-list-group-item v-if="false">
            <b-form-radio
              id="planTeam"
              v-model="checkoutSubscription.plan"
              name="selectedPlan"
              value="team"
            ></b-form-radio>
            <label for="planTeam" class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-0"><i class="fas fa-users"></i> Plano Time</h5>
                <p class="lead mb-0">R$ 39,90/<small>mês</small></p>
              </div>
              <ul class="list-unstyled mb-0">
                <li>Compartilhamento e comentários privados</li>
                <li>300 partidas privadas por mês</li>
                <li>Acesso em primeira mão aos novos recursos</li>
                <li>Pré-jogo / Lista de times</li>
                <li>Sessões de análise ao vivo com chat</li>
                <li>6 usuários inclusos</li>
              </ul>
            </label>
          </b-list-group-item>
        </b-list-group>

        <b-alert variant="light" show>
          <!-- <h4 class="alert-heading">Cupom de Desconto</h4>
          <p>
            Se você tem um cupom de desconto, valide-o abaixo. Se não tem, <a href="https://bit.ly/fbdiscord" target="_blank">chega no discord</a>.
          </p> -->

          <b-input-group class="mt-3">
            <b-form-input
              v-model="checkoutSubscription.coupon_code"
              placeholder="Cupom de desconto"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" @click.stop="redeemCoupon()"
                >Validar</b-button
              >
            </b-input-group-append>
          </b-input-group>

          <b-alert
            class="mt-2"
            show
            variant="success"
            v-if="currentCoupon.name != ''"
          >
            <h4 class="mb-0">{{ currentCoupon.name }}</h4>
            <p class="mb-0">{{ currentCoupon.description }}</p>
            <p class="mb-0 price">
              desconto de
              <strong
                >{{ currentCoupon.discount_amount.replace(".0", "") }}%</strong
              >
              do valor total
            </p>
          </b-alert>
        </b-alert>

        <b-btn
          variant="primary"
          block
          size="lg"
          @click="subscribePackage('basic')"
        >
          <!-- {{ $t('ACCOUNT.no_premium.cta') }} -->
          Contratar pacote agora
        </b-btn>
        <p class="text-center text-muted">
          <small>
            pagamentos via Stripe
            <!-- <br>
            <a target="_blank" href="https://www.paypal.com/br/smarthelp/article/como-adiciono-saldo-com-boleto-faq3896">
              quer pagar via boleto?
            </a> -->
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Upgrade",
  metaInfo() {
    return {
      title: "Contrate um plano",
    };
  },
  data() {
    return {
      user: { cellphone: "", document_cpf: "" },
      subscriptions: [],
      checkoutSubscription: {
        coupon_code: "",
        plan: "solo",
      },
      currentCoupon: {
        name: "",
      },
    };
  },
  methods: {
    initialize() {
      this.user.document_cpf = this.currentUser.document_cpf;
      this.user.cellphone = this.currentUser.cellphone;

      this.$http
        .get("subscriptions.json")
        .then((response) => (this.subscriptions = response.data));
    },
    redeemCoupon() {
      this.$http
        .get(
          `subscriptions/redeem_coupon.json?coupon_code=${this.checkoutSubscription.coupon_code}`
        )
        .then((response) => {
          this.currentCoupon = response.data;
        });
    },
    handleProfileSubmit() {
      this.$http
        .patch(`users/current_user.json`, { user: this.user })
        .then((response) => {
          this.$store.dispatch("updateUserProfile", response.data);
          this.initialize();
          this.$refs.modalProfileUpdate.hide();
        });
    },
    subscribePackage(planName) {
      // ,package: planName
      this.$http
        .post("subscriptions.json", {
          subscription: this.checkoutSubscription,
        })
        .then((response) => {
          // const stripe = window.Stripe(`pk_test_51I8QnRL2jsPOObrkUm2MYXbgwL0ZBD14YjktIZPkGYf5O5bkjkd6frCSsZmjoof4z4PcoWrB06Ht8iv0k5d3IsNL00VZDFlGN3`)
          const stripe = window.Stripe(
            `pk_live_51I8QnRL2jsPOObrk8zPj53DZboTTujTKN3vw3lfCZEEK27WahBpyAeurV1aU81eNif00ohNFiBeqg5KnOj0eZOA000EkEEzIBG`
          );
          stripe
            .redirectToCheckout({
              sessionId: response.data.payment_url, //#.sessionId
            })
            .then(this.handleResult);
          // window.location.href = response.data.payment_url;
        });
    },
    handleResult(a, b) {
      console.log(`handleResult`);
      console.log(`handleResult`);
      console.log(a, b);
    },
    friendlyStatus(status) {
      switch (status) {
        case "subscription_status_pending":
          return "Aguardando pagamento";
          break;
        case "subscription_status_sent":
          return "Aguardando pagamento";
          break;
        case "subscription_status_error":
          return "Erro - fale conosco";
          break;
        case "subscription_status_payed":
          return "Pago";
          break;
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="sass" scoped>
.plans-selector
  margin-bottom: 20px
  .list-group-item
    display: flex
    cursor: pointer
    label
      width: 100%
      cursor: pointer
    .custom-radio
      display: inline-block
      height: 100%
</style>
